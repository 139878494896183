import React from 'react';
import { Typography } from '@material-ui/core';

export class Header extends React.Component {
  render = () => (
    <>
      <Typography variant="h2">
        CS Landscape - Technology radar
      </Typography>
      <Typography variant="h4">
        Updated 06-2020
      </Typography>
      {this.props.children}
    </>
  );
}

