import { IEntry } from '../types/IEntry';

export const ALL_ENTRIES: IEntry[] = [
   {
    'ring': 'ADOPT',
    'label': 'OpenAPI',
    'link': 'https://swagger.io/blog/news/announcing-openapi-3-0/',
    'tags': [
      'q0-languages',
      'language',
      'development',
      'design'
    ]
  },
  {
    'ring': 'ASSESS',
    'label': 'GraphQL',
    'link': 'https://graphql.org/',
    'tags': [
      'q0-languages',
      'language',
      'development',
      'design',
      'graph',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Safescale',
    'link': 'https://github.com/CS-SI/SafeScale',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'utility',
      'development',
      'dev-ops',
      'cloud',
      'security'
    ],
    'moved': 1
  },
  {
    'ring': 'ADOPT',
    'label': 'Harbor',
    'link': 'https://goharbor.io/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'utility',
      'container',
      'dev-ops',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Kafka',
    'link': 'https://kafka.apache.org/',
    'tags': [
      'q3-data-management',
      'infrastructure',
      'monitoring',
      'queue',
    ]
  },
  {
    'ring': 'TRIAL',
    'label': 'Pulsar',
    'link': 'https://kafka.apache.org/',
    'tags': [
      'q3-data-management',
      'infrastructure',
      'queue',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Dask',
    'link': 'https://dask.org/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'workflow',
      'machine-learning'
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Spark',
    'link': 'https://spark.apache.org/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'workflow',
      'big-data',
      'cloud'
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Argo',
    'link': 'https://argoproj.github.io/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'workflow',
      'big-data',
      'cloud'
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'KeyCloak',
    'link': 'https://www.keycloak.org/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'security'
    ]
  },
  {
    'ring': 'TRIAL',
    'label': 'Rancher',
    'link': 'https://rancher.com/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'container',
      'dev-ops',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Prometheus',
    'link': 'https://prometheus.io/',
    'tags': [
      'q1-infrastructure',
      'utility',
      'metrics',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Fluentd',
    'link': 'https://www.fluentd.org/',
    'tags': [
      'q1-infrastructure',
      'utility',
      'metrics',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'Minio',
    'link': 'https://www.minio.io/',
    'tags': [
      'q1-infrastructure',
      'infrastructure',
      'database',
      'dev-ops',
    ]
  },
  {
    'ring': 'ADOPT',
    'label': 'TensorFlow',
    'tags': [
      'q2-frameworks',
      'framework',
      'machine-learning',
      'development'
    ],
    'link': 'https://www.tensorflow.org/'
  },
  {
    'ring': 'ADOPT',
    'label': 'Jupyter',
    'tags': [
      'q2-frameworks',
      'framework',
      'development',
      'notebook',
      'data intelligence'
    ],
    'link': 'https://jupyter.org/'
  },
  {
    'ring': 'ASSESS',
    'label': 'OpenFAAS',
    'tags': [
      'q2-frameworks',
      'framework',
      'development',
      'serverless'
    ],
    'link': 'https://www.openfaas.com/'
  },
  {
    'ring': 'ADOPT',
    'label': 'Elasticsearch',
    'tags': [
      'q3-data-management',
      'database',
      'no-sql',
      'gis'
    ],
    'link': 'https://www.elastic.co/fr/elasticsearch/'
  },
  {
    'ring': 'ADOPT',
    'label': 'Kibana',
    'tags': [
      'q3-data-management',
      'data intelligence',
    ],
    'link': 'https://www.elastic.co/fr/kibana'
  },
  {
    'ring': 'ASSESS',
    'label': 'Apache NiFi',
    'tags': [
      'q3-data-management',
      'database',
      'no-sql',
      'data intelligence'
    ],
    'link': 'https://nifi.apache.org/'
  },
  {
    'ring': 'ADOPT',
    'label': 'PostgreSQL',
    'tags': [
      'q3-data-management',
      'database',
      'sql'
    ],
    'link': 'https://www.postgresql.org/'
  },
  {
    'ring': 'ADOPT',
    'label': 'PostGIS',
    'tags': [
      'q3-data-management',
      'database',
      'sql',
      'gis'
    ],
    'link': 'https://postgis.net/'
  },

  // ----- metodologie
  {
    'ring': 'ADOPT',
    'label': 'SOLID',
    'tags': [
      'q0-languages',
      'principle',
    ],
    'link': 'https://en.wikipedia.org/wiki/SOLID'
  },
  {
    'ring': 'ADOPT',
    'label': 'KISS',
    'tags': [
      'q0-languages',
      'principle',
      'methodology'
    ],
    'link': 'https://fr.wikipedia.org/wiki/Principe_KISS'
  },
  {
    'ring': 'ADOPT',
    'label': 'OGC Standard',
    'tags': [
      'q0-languages',
      'interroperability',
      'gis'
    ],
    'link': 'https://www.ogc.org/standards'
  },
  {
    'ring': 'ADOPT',
    'label': 'CWL',
    'tags': [
      'q0-languages',
      'interroperability',
      'workflow'
    ],
    'link': 'https://www.commonwl.org/'
  },
  {
    'ring': 'ADOPT',
    'label': 'stac',
    'tags': [
      'q0-languages',
      'interroperability',
      'gis'
    ],
    'link': 'https://stacspec.org/',
    'moved': 1
  },
];
