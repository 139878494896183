import React from 'react';
import { Typography } from '@material-ui/core';

const Description = () => (
  <>
    <Typography variant="h6">
      What is the Tech Radar?
    </Typography>

    <Typography variant="subtitle2">
      This tool shows our skills and directions we use to mantains or develop new products.
      The Tech Radar is a list of technologies, methodologies,  complemented by an assessment result, called <em>ring
        assignment</em>. We use four rings with the following semantics:
    </Typography>


    <ul>
      <li>
        <Typography variant="body2">
          <strong>ADOPT</strong> &mdash; Used in production environment, low risk and recommended to
          be widely used.
          </Typography>
      </li>

      <li>
        <Typography variant="body2">
          <strong>TRIAL</strong> &mdash; Technologies that we started to use, solve a
          real problem; TRIAL technologies are slightly more risky
          </Typography>
      </li>

      <li>
        <Typography variant="body2">
          <strong>ASSESS</strong> &mdash; Technologies that are promising and have clear potential value-add for us;
          We started a prototyping effort.
          ASSESS technologies have higher risks.
          </Typography>
      </li>

      <li>
        <Typography variant="body2">
          <strong>HOLD</strong> &mdash; HOLD technologies should not be used for new projects,
          but usually can be continued for existing projects.
          </Typography>
      </li>
    </ul>

    <Typography variant="subtitle1">
        Follow us on <a className="social-icon" title="Linkedin"
          href="https://fr.linkedin.com/company/cs_group" target="_BLANK" rel="noopener noreferrer" >
          <img alt="linkedin" src="./img/In-Black-34px-R.png"></img>
        </a>
    </Typography>
    
    <Typography variant="subtitle2">

    </Typography>

    <Typography align="center">
      &copy; 2020 <a href="https://www.c-s.fr/">CS Group France</a> -
      Based on the work of <a href="https://www.thoughtworks.com/radar">ThoughtWorks</a>,&nbsp;
      <a href="https://github.com/yaneek/tech-radar">Grzegorz Marchwiński</a>&nbsp;
      and <a href="https://github.com/zalando/tech-radar">Zalando</a>.
    </Typography>
  </>
)

export default Description;
